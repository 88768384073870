<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation autocomplete="new-password">
			<ti-card :icon="$icons[type]">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> {{ pageTitle }}</template>
				<template #title-actions-shown>
					<v-btn elevation="2" fab small class="mr-4" @click="preview" title="Preview">
						<v-icon>mdi-eye</v-icon>
					</v-btn>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this resource? All users progress will also be deleted." button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>

				<ti-form-layout>
					<template #sidebar>

						<v-col cols="12">
							<v-select v-model="value.status" :items="resourceStatuses" item-text="label" item-value="value" label="Status"></v-select>
						</v-col>

						<v-col cols="12">
							<ti-date-picker v-model="value.published_date" label="Published date"></ti-date-picker>
						</v-col>
					</template>

					<template>
						<v-col cols="12">
							<v-text-field v-model="value.title" :rules="validations.title" label="Title"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="value.subtitle" :rules="validations.subtitle" label="Subtitle"></v-text-field>
						</v-col>

						<v-col cols="12" class="mt-5">
							<v-text-field v-model="value.mp4_link" label="MP4 Link"></v-text-field>
						</v-col>

					</template>
				</ti-form-layout>
			</ti-card>

			<ti-card :icon="$icons.document">
				<template #title>Documents</template>
				<ti-form-layout hide-title>
					<template>
						<v-col cols="12" v-for="(document, index) in value.documents_attributes">
							<v-row v-if="!document._destroy">
								<v-col>
									<ti-document-upload label="Document" v-model="value.documents_attributes[index]"></ti-document-upload>
								</v-col>
								<v-col cols="auto">
									<v-btn color="error" elevation="2" fab small class="mr-4" @click="deleteDocument">
										<v-icon>{{ $icons.delete }}</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-col>

						<v-col>
							<v-btn color="primary" elevation="2" fab small class="mr-4" @click="addDocument">
								<v-icon>{{ $icons.plus }}</v-icon>
							</v-btn>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

		</v-form>
	</v-container>
</template>
<script>

import validationRules from "../../util/validation";
import {sync, get} from "vuex-pathify";
import helperMixin from "../../mixins/helperMixin";
import TiDatePicker from "../../components/TiDatePicker";
import TiFormLayout from "../../components/TiFormLayout";
import TiPillCombo from "../../components/TiPillCombo";
import models from "../../models"
import TiColorPicker from "../../components/TIColorPicker";
import getTextColorForBackground from "../../util/colorContrast";
import TiShape from "../../components/TiShape.vue";

export default {
	name: "ResourcesForm",
	components: {TiShape, TiColorPicker, TiPillCombo, TiFormLayout, TiDatePicker},
	props: ['id', 'value', 'type', 'plural', 'title'],
	mixins: [helperMixin],
	computed: {
		cta_bg_color() {
			if (this.value.resource_content_attributes.cta_bg_color) {
				return this.value.resource_content_attributes.cta_bg_color
			} else {
				return '#2f5e5b'
			}
		},
		cta_shape_color_1() {
			if (this.value.resource_content_attributes.cta_shape_color_1) {
				return this.value.resource_content_attributes.cta_shape_color_1
			} else {
				return '#ade041'
			}
		},
		cta_shape_color_2() {
			if (this.value.resource_content_attributes.cta_shape_color_2) {
				return this.value.resource_content_attributes.cta_shape_color_2
			} else {
				return '#eac2ce'
			}
		},
		typeCapitalized() {
			return this.type[0].toUpperCase() + this.type.substring(1)
		},
		pluralCapitalized() {
			return this.plural[0].toUpperCase() + this.plural.substring(1)
		},
		isEdit() {
			return this.$route.meta?.edit
		},
		resource_tags: {
			get() {
				return this.value.resource_tags?.split(',')
			},
			set(value) {
				this.value.resource_tags = value.join(',')
			}
		},
		topics_for_resources: get('topics/forResources'),
		topics: sync('topics/topics'),
		brands: sync('brands/brands'),
		related_resources: sync('resources/resources'),
		xapi_contents: sync('xapi_contents/xapi_contents'),
		pageTitle() {
			if (this.title) return this.title
			return this.typeCapitalized
		},
	},
	watch: {
		resource(newValue) {
			this.$emit('input', newValue);
		},
		'value.title'(value) {
			if (!this.value.id || (this.value.id && !this.value.slug)) {
				this.value.slug = this.slugify(value)
			}
			if (!this.value.id || (this.value.id && !this.value.meta_title)) {
				this.value.meta_title = value
			}
		},
		'value.subtitle'(value) {
			if (!this.value.id || (this.value.id && !this.value.meta_description)) {
				this.value.meta_description = value
			}
		}
	},
	data: () => ({
		image: '',
		fab: false,
		subDrawer: false,
		valid: false,
		validations: {
			title: validationRules('Title', ['required', 'minLength:2']),
			xapi_content: validationRules('Xapi Content', ['required']),
			topics: validationRules('Topics', ['required', 'minLength:1']),
			meta_title: validationRules('Meta title', ['required', 'minLength:2']),
			meta_description: validationRules('Meta description', ['required', 'minLength:2']),
			caption: validationRules('Caption', ['required', 'minLength:2']),
		},
		resourceTagOptions: [
			{text: 'Article', value: 'article'},
			{text: 'Case Study', value: 'case_study'},
			{text: 'Infographic', value: 'infographic'},
			{text: 'Podcast', value: 'podcast'},
			{text: 'Video', value: 'video'},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		getTextColorForBackground,
		init() {
			this.$store.set('topics/getTopics!', null)
			this.$store.set('brands/getBrands!', null)
			this.$store.set('resources/getResources!', null)
			this.$store.set('xapi_contents/getXapi_contents!', null)
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.resources.update(this.id, this.value)
						.then(response => {
							this.$toast.add(`${this.typeCapitalized} updated successfully`, 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: `${this.pluralCapitalized}`})
						})
						.catch(error => this.$toast.handleResponseErrors(this, error)
						)
				} else {
					this.$api.resources.create(this.value)
						.then(response => {
							this.$toast.add(`${this.typeCapitalized} created successfully`, 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: `${this.pluralCapitalized}`})
						})
						.catch(error => {
							this.$toast.handleResponseErrors(this, error)
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.resources.delete(this.id, this.resource)
				.then(response => {
					this.$toast.add(`${this.typeCapitalized} deleted successfully`, 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: `${this.pluralCapitalized}`})
				})
				.catch(error => {
					this.$toast.add(error.response.statusText, 'error')
				})
		},
		addRedirect() {
			this.value.url_redirects_attributes.push(models.UrlRedirect())
		},
		addRelatedResource() {
			this.value.related_resources_attributes.push(models.RelatedResource())
		},
		deleteRedirect(redirect, index) {
			if (redirect.id !== null) {
				this.course.url_redirects_attributes[index]['_destroy'] = true
			} else {
				this.course.url_redirects_attributes.splice(index, 1)
			}
		},
		deleteDocument() {
			return true
		},
		addDocument() {
			this.value.documents_attributes.push(models.Document())
		},
		recentlyUpdated() {
			this.value.updated_date = new Date().toISOString().slice(0, 10)
		},
		preview() {
			if (process.env.production) {
				window.open(`https://targetinternet.com/resources/${this.value.slug}`, '_blank');
			} else {
				window.open(`http://localhost:3000/resources/${this.value.slug}`, '_blank');
			}
		}
	}
}
</script>