const getTextColorForBackground = (bgColor) => {
	try {
		if (!bgColor) return 'white';
		const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
		const r = parseInt(color.substring(0, 2), 16); // hexToR
		const g = parseInt(color.substring(2, 4), 16); // hexToG
		const b = parseInt(color.substring(4, 6), 16); // hexToB
		const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
		return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
			'black' : 'white';
	} catch (error) {
		console.log('Error in getTextColorForBackground:');
		// console.log('Error in getTextColorForBackground:', error);
		return 'black'; // Return 'black' in case of an error
	}
};

export default getTextColorForBackground;