<template>
	<div>
		<intermission-form  :id="id" type="intermission" plural="intermissions" v-model="intermission"></intermission-form>
	</div>
</template>

<script>

import {sync} from "vuex-pathify";
import intermissionForm from '@/views/resources/IntermissionForm'
import models from '@/models'

export default {
	name: "IntermissionsForm",
	components: {intermissionForm},
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		intermission: sync('intermissions/intermission'),
	},
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.$store.set('topics/getTopics!', null)
			if (this.isEdit) {
				this.$store.set('intermissions/getIntermission!', this.id)
			} else {
				this.intermission = new models.Resource('Intermission')
			}
		}
	}
}
</script>